import React, { Component, useEffect, useState, useRef, lazy } from "react";
import {
  Button,
  Nav,
  Navbar,
  NavDropdown,
  MenuItem,
  NavItem,
  Dropdown,
  Card
} from "react-bootstrap";
import { render } from "react-dom";
import ReactDOM from "react-dom";
import classnames from "classnames";
import Api from "../../../package.json";
import * as axios from "axios";
import { NavLink as Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { withRouter, browserHistory } from "react-router";
import { useForm, Controller } from "react-hook-form";
//import './header.css';
import gsl_logo from "../../../src/assets/gsl-logo.png";
import arrow_menu_h from "../../../src/assets/arrow-menu-h.png";
import menu_inn from "../../../src/assets/menu-inn.png";
import arrow_read from "../../../src/assets/arrow-read.png";
import arrow_down from "../../../src/assets/arrow-down.png";
import MenuBox from "../../../src/assets/menu.svg";
import CloseBtn from "../../../src/assets/close.png";
import headerLang from "../lang/header";
import "../header/header.css";
import { slide as Menu } from "react-burger-menu";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Select from "react-select";
import ReactGA from 'react-ga';


const qs = require("qs");
var jwt = require("jsonwebtoken");

const Header = () => {
  var langs = window.localStorage.getItem("lang");


  const history = useHistory();
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(true);
  const [menuToggle, setmenuToggle] = useState(false);
  const [header_content, setheader_content] = useState([]);
  const [enquiry_email, setenquiry_email] = useState(window.localStorage.getItem('enquiry_email'));
  const [contact, setcontact] = useState(window.localStorage.getItem('contact'));
  const [header_json_content, setheader_json_content] = useState([]);
  const [country_code, setcountry_code] = useState(window.$country_code);
  const [lang, setLang] = useState('');
  const [langcode, setLangcode] = useState('');
  const {
    register,
    control,
    setValue,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const [flag_url, setflag_url] = useState({
    AU: "Australia.png",
    IN: "India.png",
    JP: "Japan.png",
    SG: "Singapore.png",
    KR: "South Korea.png",
    CNS: "China.png",
    TW: "Taiwan.png",
    HK: "global.png",
    MY: "Malaysia.png",
    TH: "Thailand.png",
    VN: "Vietnam.png",
    ID: "Indonesia.png",
    NG: "Nigeria.png",
    CNN: "China.png",
    BD: "Bangladesh.png",
    NZ: "New Zealand.png",
  });
  const [regional_url, setregional_url] = useState({
    HK: "/",
    AU: "/australia",
    IN: "/India",
    JP: "/Japan",
    MY: "/Malaysia",
    NG: "/Nigeria",
    CNN: "/north_china",
    SG: "/singapore",
    KR: "/south_korea",
    CNS: "/south_china",
    TW: "/taiwan",
    TH: "/Thailand",
    VN: "/Vietnam",
    ID: "/Indonesia",
    BD: "/Bangladesh",
    NZ: "/new_zealand"
  });

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const loadlocaldata = async () => {
    const headers = {
      type: "application/json",
      Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
    };
    var lang = window.localStorage.getItem('lang');
    const response = await axios.post(
      Api.userdata.url + "/header_contents",
      qs.stringify({ country_code: window.localStorage.getItem('country_code'), lang: lang }, { parseArrays: false }),
      { headers: headers }
    );
    const json = await response.data;
    var json_data = JSON.parse(json["message"][0]["details"]);
    window.localStorage.setItem('enquiry_email', json_data["enquiry_email"]);
    window.localStorage.setItem('contact', json_data["contact"]);
    setcontact(json_data["contact"]);
    setenquiry_email(json_data["enquiry_email"]);


  }

  const goTororo = () => {
    const violation = document.getElementById('roro-log');
    if (violation) {
      window.scrollTo({
        top: violation.offsetTop - 100,
        behavior: "smooth"
      });
    } else {

      if (regional_url[country_code] == '/') {
        history.push('global/viewRoro')
      }
      else {
        history.push(regional_url[country_code] + '/viewRoro')
      }


    }
  }
  const setlangfn = (lang) => {
    window.localStorage.setItem('lang', lang);
    setLang(lang);
    if (lang == 'cn') {
      setLangcode('CHT')
    }
    else if (lang == 'zh') {
      setLangcode('CHS')
    }
    else {
      setLangcode(lang)
    }

    window.location.reload();
  };
  const getLoadData = async () => {

    var stored_country_code = window.localStorage.getItem('country_code');
    if (stored_country_code == null) {
      window.localStorage.setItem('country_code', country_code);
      loadlocaldata();
    }
    else if (window.localStorage.getItem('country_code') != country_code) {
      window.localStorage.setItem('country_code', country_code);
      loadlocaldata();
    }
    if (window.localStorage.getItem('enquiry_email') == null || window.localStorage.getItem('contact') == null) {
      loadlocaldata();
    }

    if (window.localStorage.getItem("lang") == null) {
      setLang('en');
      setLangcode('en');
    }
    else {
      if (window.localStorage.getItem("lang") == 'cn') {
        setLangcode('CHT')
      }
      else if (window.localStorage.getItem("lang") == 'zh') {
        setLangcode('CHS')
      }
      else {
        setLangcode(window.localStorage.getItem("lang"))
      }
    }



  };

  const handleClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
   
  };
  useEffect(() => {

    ReactGA.initialize('UA-139049524-1', {
      debug: true,
      titleCase: true,

    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    getLoadData();
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (window.innerWidth > 768) {
        if (prevScrollY.current < currentScrollY && goingUp) {
          setGoingUp(false);
        }
        if (prevScrollY.current > currentScrollY && !goingUp) {
          setGoingUp(true);
        }

        prevScrollY.current = currentScrollY;
        console.log(goingUp, currentScrollY);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp, enquiry_email, contact]);


  var styles = {

    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      left: "36px",
      top: "36px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      display: "block"
    },
    bmMenu: {
      background: "#373a47",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };
  const menuSlide = () => {
    setmenuToggle(!menuToggle);
  };

  return (
    <>

      <CookieConsent
        location="bottom"
        buttonText="I Agree"
        cookieName="gsl-cookie"
        style={{ background: "#154070" }}
        buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
        expires={150}
      >
        We use cookies and tracking to improve the usability and functionality of your browsing experience and for analytics and marketing purposes. By clicking on the "I Agree" button below, you hereby consent to our use of cookies and tracking, Alternatively, by continuing to browse this website, you accept the use of cookies and tracking. For more information about the cookies used, disabling them and opting-out
        <Link className="news-cont-next-link" to="/gsl-cookies-and-tracking-policy" style={{ float: "none" }}>{'  '}Read More...</Link>
      </CookieConsent>
      <div className="main-header fixed-top" id="header">
        <div
          className="top-info"
          style={
            !goingUp
              ? {
                opacity: "0",
                display: "none",
                visibility: `hidden`,
                opacity: 0,
                transition: `visibility 0s, opacity 0.5s linear`,
              }
              : { display: "block" }
          }
          id="head-info-tab"
        >
          <div className="container-new">
            <ul className="header-message list-inline top-info-left-ul">
              <li className="list-inline-item">
                {" "}
                <span className="ship_pp ship_ppp">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  {enquiry_email}
                </span>
              </li>
              <li className="list-inline-item">
                <span className="ship_ppp">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  {contact}
                </span>
              </li>
            </ul>
            <ul className="list-inline  top-info-right-ul">
              <li className="dropdown dropdown-hovers list-inline-item">
                <a href="javascript:void(0);" class="dropdown-toggle contact-drop-arrow">
                  {headerLang[langs].ContactUs}
                </a>
                <ul class="dropdown-menu menu-contact-bg">
                  <li>
                    <Link to="/contact_us" className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang[langs].ContactUs}
                    </Link>

                  </li>
                  <li>
                    <Link to="/enquiry" className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang[langs].Enquiry}

                    </Link>
                  </li>
                  <li>
                    <Link to="/faq" className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang[langs].HelpFAQGlossary}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="list-inline-item">
                <Link to="/newsletter" className="drop-inner">
                  <img src={arrow_menu_h} className="head-arrow-h" />
                  {headerLang[langs].Newsletter}
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/career" className="drop-inner">
                  <img src={arrow_menu_h} className="head-arrow-h" />
                  {headerLang[langs].Career}

                </Link>
              </li>
              <li className="dropdown dropdown-hovers list-inline-item">
                <a href="javascript:void(0);" class="dropdown-toggle contact-drop-arrow">
                  {headerLang[langs].Helps}
                </a>
                <ul class="dropdown-menu menu-help-bg">
                  <li className="dropdown dropdown-hovers">
                    <a
                      href="javascript:void(0);"
                      class="dropdown-toggle help-drop-arrow drop-inner"
                    >
                      {headerLang[langs].Usefulinformation}
                    </a>
                    <ul className="dropdown-menu menu-help-bg knowledge-list">
                      <li>
                        <Link to="/customer_advisory" className="drop-inner">
                          <img src={arrow_menu_h} className="head-arrow-h" />
                          {headerLang[langs].CustomerAdvisory}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/quotation_terms_conditions"
                          className="drop-inner"
                        >
                          <img src={arrow_menu_h} className="head-arrow-h" />
                          {headerLang[langs].QuotationTermsConditions}
                        </Link>
                      </li>
                      <li>
                        <Link to="/booking_clause" className="drop-inner">
                          <img src={arrow_menu_h} className="head-arrow-h" />
                          {headerLang[langs].BookingConfirmationclause}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/bl_terms_and_conditions"
                          className="drop-inner"
                        >
                          <img src={arrow_menu_h} className="head-arrow-h" />
                          {headerLang[langs].BLTermsConditions}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/sea-way-bill-terms-and-condition"
                          className="drop-inner"
                        >
                          <img src={arrow_menu_h} className="head-arrow-h" />
                          {headerLang[langs].SEAWAYBILL}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/gsl-go-terms-and-condition"
                          className="drop-inner"
                        >
                          <img src={arrow_menu_h} className="head-arrow-h" />
                          {headerLang[langs].GslgoTermsConditions}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/egsl-terms-and-condition"
                          className="drop-inner"
                        >
                          <img src={arrow_menu_h} className="head-arrow-h" />
                          {headerLang[langs].eGSLTermsConditions}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/terms_of_use" className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang[langs].Termsofuse}
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="dropdown dropdown-hovers list-inline-item" id="flag_login_base_mob">
                <a href="#" class="dropdown-toggle contact-drop-arrow">
                  {country_code == "HK" ? headerLang[langs].Global : country_code}{" "}
                </a>
                <ul class="dropdown-menu menu-help-bg">

                  <li className="menu-countyy" data-id="HK">
                    <Link to="/">
                      <img
                        src={require("../../../src/assets/flagicon/global.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].GlobalHongKongSARChina}
                      {/* <span className="text-right pl-4">Hong Kong SAR,China{" "}
                          </span> */}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="AU">
                    <Link to="/Australia" >
                      <img
                        src={require("../../../src/assets/flagicon/Australia.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Australia}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="BD">
                    <Link to="/Bangladesh" >
                      <img
                        src={require("../../../src/assets/flagicon/Bangladesh.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Bangladesh}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="IN">
                    <Link to="/India"  >
                      <img
                        src={require("../../../src/assets/flagicon/India.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].India}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="JP">
                    <Link to="/Japan">
                      <img
                        src={require("../../../src/assets/flagicon/Japan.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Japan}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="ID">
                    <Link to="/Indonesia">
                      <img
                        src={require("../../../src/assets/flagicon/Indonesia.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Indonesia}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="MY">
                    <Link to="/Malaysia">
                      <img
                        src={require("../../../src/assets/flagicon/Malaysia.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Malaysia}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="NG">
                    <Link to="/Nigeria">
                      <img
                        src={require("../../../src/assets/flagicon/Nigeria.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Nigeria}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="CNN">
                    <Link to="/north_china">
                      <img
                        src={require("../../../src/assets/flagicon/China.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].NorthChina}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="SG">
                    <Link to="/singapore">
                      <img
                        src={require("../../../src/assets/flagicon/Singapore.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Singapore}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="KR">
                    <Link to="/south_korea">
                      <img
                        src={require("../../../src/assets/flagicon/Korea.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].SouthKorea}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="CNS">
                    <Link to="/south_china">
                      <img
                        src={require("../../../src/assets/flagicon/China.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Southchina}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="TW">
                    <Link to="/taiwan">
                      <img
                        src={require("../../../src/assets/flagicon/Taiwan.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Taiwan}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="TH">
                    <Link to="/Thailand">
                      <img
                        src={require("../../../src/assets/flagicon/Thailand.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Thailand}
                    </Link>
                  </li>

                  <li className="menu-countyy" data-id="VN">
                    <Link to="/Vietnam">
                      <img
                        src={require("../../../src/assets/flagicon/Vietnam.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].Vietnam}
                    </Link>
                  </li>
                  <li className="menu-countyy" data-id="NZ">
                    <Link to="/new_zealand">
                      <img
                        src={require("../../../src/assets/flagicon/New Zealand.png")}
                        style={{ width: `20px` }}
                      />
                      {headerLang[langs].new_zealand}
                    </Link>
                  </li>


                </ul>
              </li>
              <li className="dropdown dropdown-hovers list-inline-item">
                <a href="javascript:void(0);" class="dropdown-toggle contact-drop-arrow">
                  {langcode.toUpperCase()}
                </a>
                <ul class="dropdown-menu menu-help-bg">
                  <li>
                    <a href="javascript:void(0);" onClick={() => {

                      setlangfn('en');

                    }} className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      English
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" onClick={() => {

                      setlangfn('cn');

                    }} className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang['cn'].headerLang}
                    </a>
                  </li> <li>
                    <a href="javascript:void(0);" onClick={() => {

                      setlangfn('zh');

                    }} className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang['zh'].headerLang}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" onClick={() => {

                      setlangfn('ko');

                    }} className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang['ko'].headerLang}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" onClick={() => {

                      setlangfn('vi');

                    }} className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang['vi'].headerLang}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" onClick={() => {

                      setlangfn('th');

                    }} className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang['th'].headerLang}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" onClick={() => {

                      setlangfn('in');

                    }} className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang['in'].headerLang}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" onClick={() => {

                      setlangfn('ja');

                    }} className="drop-inner">
                      <img src={arrow_menu_h} className="head-arrow-h" />
                      {headerLang['ja'].headerLang}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="header-menu-top container-fluid1"
          style={!goingUp ? { paddingTop: "0px" } : {}}
        >
          <nav className="container-new navbar navbar-expand-lg navbar-light  head-navbar">
            <Link to={regional_url[country_code]} > <img src={gsl_logo} alt="gsl-logo" /></Link>
            <button
              onClick={(e) => menuSlide()}
              className="navbar-toggler menu-respons-but"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <img src={MenuBox} />
              </span>
            </button>
            <div
              id="navbarNavDropdown"
              className={"navbar-collapse collapse1 navbar-open menuClose "}
            >
              <ul className="navbar-nav nav ml-left ml-lg-0 ml-md-0 nav-bar-design ">
                <li className="dropdown dropdown-hovers">
                  <a href="javascript:void(0);" className="dropdown-toggle menu-heads">
                    {headerLang[langs].Schedules}
                    <span class="down-arrows-menu">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAICAMAAAARDVXAAAAAOVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC8dlA9AAAAEnRSTlMAybgaFQX31LLqrJtrYDslDw2wiyw2AAAAQklEQVQI12NgEmJmgAFWIUYGQQ64AAsbI5AJVMEClWUHkmABPiDJz8YI5kFVCABl4YBbiJeTiwEJcABNRgE8rBAaAGzFAYQ9mjbRAAAAAElFTkSuQmCC" />
                    </span>
                  </a>
                  <ul className={'dropdown-menu main-menu  mmenu1 desk_schedule desk_schedule_' + langs}>
                    <div className="row m-0 menu-full-pop gsl_menu1 single-menu1">

                      <div className="col-lg-12 col-md-12 col-sm-12 col-12  p-0 head-menu-liss">
                        <li>
                          <Link to="/point_to_point" className="drop-inner">
                            <img src={arrow_menu_h} className="head-arrow-h" />
                            {headerLang[langs].PointtoPoint}
                          </Link>
                        </li>
                        <li>
                          <Link to="/schedule_by_line" className="drop-inner">
                            <img src={arrow_menu_h} className="head-arrow-h" />
                            {headerLang[langs].SchedulebyLine}
                          </Link>
                        </li>
                        <li>
                          <Link to="/subscribe" className="drop-inner">
                            <img src={arrow_menu_h} className="head-arrow-h" />
                            {headerLang[langs].SubscribeSchedules}
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </li>
                <li className="dropdown dropdown-hovers">
                  <a href="javascript:void(0);" className="dropdown-toggle menu-heads">
                    {headerLang[langs].Tools}
                    <span class="down-arrows-menu">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAICAMAAAARDVXAAAAAOVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC8dlA9AAAAEnRSTlMAybgaFQX31LLqrJtrYDslDw2wiyw2AAAAQklEQVQI12NgEmJmgAFWIUYGQQ64AAsbI5AJVMEClWUHkmABPiDJz8YI5kFVCABl4YBbiJeTiwEJcABNRgE8rBAaAGzFAYQ9mjbRAAAAAElFTkSuQmCC" />
                    </span>
                  </a>
                  <ul className={'dropdown-menu main-menu mmenu2 desk_tools desk_tools_' + langs}>
                    <div
                      className="row m-0 menu-full-pop gsl_menu2"
                    // style={{ height: `350px` }}
                    >

                      <div className="col-lg-12 col-md-12 col-sm-12 col-12  p-0 head-menu-liss">
                        <div className="row m-0">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 p-0 ">
                            <li>
                              <Link to="/track_shipment" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].TrackaShipment}

                              </Link>
                            </li>
                            <li>

                              <Link
                                to="/tariff_calculator"
                                className="drop-inner"
                              >
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].TariffCalculator}
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/tare_weight_inquiry"
                                className="drop-inner"
                              >
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].TareWeightInquiry}
                              </Link>
                            </li>
                            <li>
                              <Link to="/submit_vgm" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].SubmitVGM}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/vgm_mass_upload"
                                className="drop-inner"
                              >
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].VGMMassUpload}

                              </Link>
                            </li>

                            <li>
                              <Link to="/sob_india" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].SOBIndiaOnly}

                              </Link>
                            </li>
                            <li>
                              <Link to="/roro" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].CarShippingQuote}

                              </Link>
                            </li>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 p-0 ">
                            <li>
                              <Link
                                to="/request_a_quote"
                                className="drop-inner"
                              >
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].RequestQuotation}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/booking_submission/"
                                className="drop-inner"
                              >
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].BookingSubmission}
                              </Link>
                            </li>
                            <li>
                              <Link to="/si_submission/" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].SISubmission}
                              </Link>
                            </li>

                            <li>
                            <Link  to="/ebl_service/" className="drop-inner">
                   
                            

                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].RegistereBLService}
                              </Link>
                            </li>
                            <li>
                            <Link to="#" onClick={() => handleClick('https://my.goldstarline.com/')} className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                  target="_new"
                                />
                                {headerLang[langs].PrintBLService}
                              </Link>
                            </li>
                            <li>
                              <Link to="/landing_gslgo" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                  target="_new"
                                />
                                {headerLang[langs].GSLGOQuoteBookGo}
                              </Link>
                            </li>

                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>
                <li className="dropdown dropdown-hovers">
                  <a href="javascript:void(0);" className="dropdown-toggle menu-heads">
                    {headerLang[langs].CargoServices}
                    <span class="down-arrows-menu">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAICAMAAAARDVXAAAAAOVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC8dlA9AAAAEnRSTlMAybgaFQX31LLqrJtrYDslDw2wiyw2AAAAQklEQVQI12NgEmJmgAFWIUYGQQ64AAsbI5AJVMEClWUHkmABPiDJz8YI5kFVCABl4YBbiJeTiwEJcABNRgE8rBAaAGzFAYQ9mjbRAAAAAElFTkSuQmCC" />
                    </span>
                  </a>
                  <ul className={'dropdown-menu main-menu mmenu3 desk_cargo desk_cargo_' + langs}>
                    <div className="row m-0 menu-full-pop gsl_menu3">

                      <div className="col-lg-12 col-md-12 col-sm-12 col-12  p-0 head-menu-liss">
                        <div className="row m-0">
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 p-0 ">
                            <li>
                              <Link to="/cargo_services" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].CargoServices}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/cargo_catalogue"
                                className="drop-inner"
                              >
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].CargoCatalogue}

                              </Link>
                            </li>
                            <li>
                              <Link to="/gsl_monitor" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].GSLMonitor}
                              </Link>
                            </li>
                            <li>
                              <Link to="/dry_cargo" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].DryCargo}
                              </Link>
                            </li>
                            <li>
                              <Link to="/reefer_cargo" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].ReeferCargo}
                              </Link>
                            </li>
                            <li>
                              <Link to="/project_cargo" className="drop-inner">
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].ProjectCargo}
                              </Link>
                            </li>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-6 p-0 ">
                            <li>
                              <Link
                                to="/inland_transport"
                                className="drop-inner"
                              >
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />{headerLang[langs].InlandTransport}

                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/temperature_controlled_shipping"
                                className="drop-inner"
                              >
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />{headerLang[langs].TemperatureControlled}

                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/dangerous_cargo"
                                className="drop-inner"
                              >
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />{headerLang[langs].DangerousCargo}

                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/high_value_or_sensitive_cargo"
                                className="drop-inner"
                              >
                                <img
                                  src={arrow_menu_h}
                                  className="head-arrow-h"
                                />
                                {headerLang[langs].ValuableCargo}

                              </Link>
                            </li>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>
                <li className="dropdown dropdown-hovers">
                  <a href="javascript:void(0);" className="dropdown-toggle menu-heads">
                    {headerLang[langs].AboutGSL}
                    <span class="down-arrows-menu">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAICAMAAAARDVXAAAAAOVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC8dlA9AAAAEnRSTlMAybgaFQX31LLqrJtrYDslDw2wiyw2AAAAQklEQVQI12NgEmJmgAFWIUYGQQ64AAsbI5AJVMEClWUHkmABPiDJz8YI5kFVCABl4YBbiJeTiwEJcABNRgE8rBAaAGzFAYQ9mjbRAAAAAElFTkSuQmCC" />
                    </span>
                  </a>
                  <ul className={'dropdown-menu main-menu main-menu-single mmenu4 desk_about desk_about_' + langs} >
                    <div className="row m-0 menu-full-pop gsl_menu4 single-menu4">

                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0 head-menu-liss">
                        <li>
                          <Link to="/about_gsl" className="drop-inner">
                            <img src={arrow_menu_h} className="head-arrow-h" />
                            {headerLang[langs].AboutGSL}
                          </Link>
                        </li>
                        <li>
                          <Link to="/company_profile" className="drop-inner">
                            <img src={arrow_menu_h} className="head-arrow-h" />
                            {headerLang[langs].CompanyProfile}
                          </Link>
                        </li>
                        <li>
                          <Link to="/company_history" className="drop-inner">
                            <img src={arrow_menu_h} className="head-arrow-h" />
                            {headerLang[langs].CompanyHistory}
                          </Link>
                        </li>
                        <li>
                          <Link to="/facts_figures" className="drop-inner">
                            <img src={arrow_menu_h} className="head-arrow-h" />
                            {headerLang[langs].FactsFigures}
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </li>
                <li className="dropdown dropdown-hovers">
                  <a href="javascript:void(0);" className="dropdown-toggle menu-heads">
                    {headerLang[langs].LocalInfo}
                    <span class="down-arrows-menu">
                      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAICAMAAAARDVXAAAAAOVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC8dlA9AAAAEnRSTlMAybgaFQX31LLqrJtrYDslDw2wiyw2AAAAQklEQVQI12NgEmJmgAFWIUYGQQ64AAsbI5AJVMEClWUHkmABPiDJz8YI5kFVCABl4YBbiJeTiwEJcABNRgE8rBAaAGzFAYQ9mjbRAAAAAElFTkSuQmCC" />
                    </span>
                  </a>
                  <ul className={'dropdown-menu main-menu main-menu-single mmenu5 desk_localinfo desk_localinfo_' + langs} >
                    <div className="row m-0 menu-full-pop gsl_menu5 single-menu5">

                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0 head-menu-liss">
                        <li>
                          <Link
                            to="/demurrage_and_detention_tariff"
                            className="drop-inner"
                          >
                            <img src={arrow_menu_h} className="head-arrow-h" />
                            <img src={arrow_menu_h} className="head-arrow-h" />
                            {headerLang[langs].DemurrageandDetentionTariff}
                          </Link>
                        </li>
                        <li>
                          <Link to="/local_info" className="drop-inner">
                            <img src={arrow_menu_h} className="head-arrow-h" />
                            {headerLang[langs].LocalInformation}
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>
                </li>
                <li>
                  {" "}
                  <div className="flag_login_base">
                    <ul className="nav navbar-nav flag-sec-respons">
                      <li className="menu-bar">
                        <img
                          src={require("../../../src/assets/flagicon/" +
                            flag_url[country_code])}
                          style={{ width: `30px` }}
                        />{" "}
                        <sup>
                          {country_code == "HK" ? headerLang[langs].Global : country_code}{" "}
                        </sup>
                      </li>
                      <li className="dropdown dropdown-hovers">
                        <a
                          className="dropdown-toggle menu-heads"
                          data-toggle="dropdown"
                          href="javascript:void(0);"
                        >
                          <span className="down-arrows">
                            <img src={arrow_down} />
                          </span>
                        </a>
                        <ul
                          className="dropdown-menu flagg-drop gsl_company_offices"
                          style={{ width: `221px`, marginLeft: `-340px` }}
                        >
                          <div className="row m-0 menu-full-pop flag-height">
                            <li className="menu-countyy flag-head" data-id="HK">
                              <Link to="/">
                                <img
                                  src={require("../../../src/assets/flagicon/global.png")}
                                  style={{ width: `20px` }}
                                />
                                {headerLang[langs].GlobalHongKongSARChina}
                                {/* <span className="text-right pl-4">Hong Kong SAR,China{" "}
                          </span> */}
                              </Link>
                            </li>
                            <div className="country-list-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                              <div className="country-list-main col-md-12">
                                <li className="menu-countyy" data-id="AU">
                                  <Link to="/Australia" >
                                    <img
                                      src={require("../../../src/assets/flagicon/Australia.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Australia}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="BD">
                                  <Link to="/Bangladesh">
                                    <img
                                      src={require("../../../src/assets/flagicon/Bangladesh.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Bangladesh}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="IN">
                                  <Link to="/India">
                                    <img
                                      src={require("../../../src/assets/flagicon/India.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].India}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="JP">
                                  <Link to="/Japan">
                                    <img
                                      src={require("../../../src/assets/flagicon/Japan.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Japan}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="ID">
                                  <Link to="/Indonesia">
                                    <img
                                      src={require("../../../src/assets/flagicon/Indonesia.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Indonesia}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="MY">
                                  <Link to="/Malaysia">
                                    <img
                                      src={require("../../../src/assets/flagicon/Malaysia.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Malaysia}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="NG">
                                  <Link to="/Nigeria">
                                    <img
                                      src={require("../../../src/assets/flagicon/Nigeria.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Nigeria}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="CNN">
                                  <Link to="/north_china">
                                    <img
                                      src={require("../../../src/assets/flagicon/China.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].NorthChina}
                                  </Link>
                                </li>
                              </div>
                            </div>
                            <div className="country-list-1 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                              <div className="country-list-main col-md-12">

                                <li className="menu-countyy" data-id="SG">
                                  <Link to="/singapore">
                                    <img
                                      src={require("../../../src/assets/flagicon/Singapore.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Singapore}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="KR">
                                  <Link to="/south_korea">
                                    <img
                                      src={require("../../../src/assets/flagicon/Korea.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].SouthKorea}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="CNS">
                                  <Link to="/south_china">
                                    <img
                                      src={require("../../../src/assets/flagicon/China.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Southchina}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="TW">
                                  <Link to="/taiwan">
                                    <img
                                      src={require("../../../src/assets/flagicon/Taiwan.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Taiwan}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="TH">
                                  <Link to="/Thailand">
                                    <img
                                      src={require("../../../src/assets/flagicon/Thailand.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Thailand}
                                  </Link>
                                </li>

                                <li className="menu-countyy" data-id="VN">
                                  <Link to="/Vietnam">
                                    <img
                                      src={require("../../../src/assets/flagicon/Vietnam.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].Vietnam}
                                  </Link>
                                </li>
                                <li className="menu-countyy" data-id="NZ">
                                  <Link to="/new_zealand">
                                    <img
                                      src={require("../../../src/assets/flagicon/New Zealand.png")}
                                      style={{ width: `20px` }}
                                    />
                                    {headerLang[langs].new_zealand}
                                  </Link>
                                </li>
                                <li className="menu-countyy">
                                  <br></br>

                                </li>

                              </div>
                            </div>
                          </div>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li>
               {/*  <li class="tcenter">
                  
                  <a href="https://my.goldstarline.com/" target="new">
                    <img
                      className="login_gsl_img"
                      width="150"
                      style={{ height: "28px" }}
                      src={require("../../../src/assets/login-gsl-new.svg").default}
                    />
                  </a>
                </li> */}
                <li class="tcenter cursor-pointer" onClick={(e) => goTororo()} >

                  <img
                    className="login_gsl_img"
                    width="150"
                    src={require("../../../src/assets/roro-logo.png")}
                  />

                </li>
              </ul>
            </div>
          </nav>
          <Menu isOpen={menuToggle} styles={styles}>
            <ul className="list-unstyled MobMenuUl">
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-button-dark-mobile-menu"
                    className="mobMenuLi"
                    variant="secondary"
                  >
                    {headerLang[langs].Schedules}
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant="dark">
                    <Dropdown.Item>
                      <Link to="/point_to_point" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].PointtoPoint}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/schedule_by_line" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].SchedulebyLine}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/subscribe" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].SubscribeSchedules}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-button-dark-mobile-menu"
                    className="mobMenuLi"
                    variant="secondary"
                  >
                    {headerLang[langs].Tools}
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant="dark">
                    <Dropdown.Item>
                      <Link to="/track_shipment" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].TrackaShipment}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/tariff_calculator" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].TariffCalculator}
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link to="/tare_weight_inquiry" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].TareWeightInquiry}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/submit_vgm" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].SubmitVGM}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/vgm_mass_upload" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].VGMMassUpload}
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link to="/request_a_quote" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].RequestQuotation}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/booking_submission/" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].BookingSubmission}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/si_submission/" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].SISubmission}
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                    <Link  to="/ebl_service/" className="drop-inner">
                     
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].RegistereBLService}
                    </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                    <Link to="#" onClick={() => handleClick('https://my.goldstarline.com/')} className="drop-inner">
                   

                        <img
                          src={arrow_menu_h}
                          className="head-arrow-h"
                          target="_new"
                        />
                        {headerLang[langs].PrintBLService}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/sob_india" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].SOBIndiaOnly}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/landing_gslgo" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].GSLGOQuoteBookGo}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/roro" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].CarShippingQuote}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-button-dark-mobile-menu"
                    className="mobMenuLi"
                    variant="secondary"
                  >
                    {headerLang[langs].CargoServices}
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="dark">

                    <Dropdown.Item>
                      <Link to="/cargo_services" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].CargoServices}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/cargo_catalogue" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].CargoCatalogue}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/gsl_monitor" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].GSLMonitor}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/dry_cargo" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].DryCargo}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/reefer_cargo" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].ReeferCargo}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/project_cargo" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].ProjectCargo}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/inland_transport" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].DryCargo}  {headerLang[langs].InlandTransport}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link
                        to="/temperature_controlled_shipping"
                        className="drop-inner"
                      >
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].TemperatureControlled}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/dangerous_cargo" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].DangerousCargo}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link
                        to="/high_value_or_sensitive_cargo"
                        className="drop-inner"
                      >
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].ValuableCargo}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-button-dark-mobile-menu"
                    className="mobMenuLi"
                    variant="secondary"
                  >
                    {headerLang[langs].AboutGSL}
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant="dark">
                    <Dropdown.Item>
                      <Link to="/about_gsl" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].AboutGSL}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/company_profile" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].CompanyProfile}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/company_history" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].CompanyHistory}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/facts_figures" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].FactsFigures}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-button-dark-mobile-menu"
                    className="mobMenuLi"
                    variant="secondary"
                  >
                    {headerLang[langs].LocalInfo}
                  </Dropdown.Toggle>

                  <Dropdown.Menu variant="dark">
                    <Dropdown.Item>
                      <Link
                        to="/demurrage_and_detention_tariff"
                        className="drop-inner"
                      >
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].DemurrageandDetentionTariff}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/local_info" className="drop-inner">
                        <img src={arrow_menu_h} className="head-arrow-h" />
                        {headerLang[langs].LocalInformation}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </Menu>
        </div >
        <div className="rr-mobile-custom-icon">
          <ul className="rr-mobile-icon ">
            {/* <li class=" ">
              <a href="https://my.goldstarline.com/" target="new" className="Flex-item">
                <img
                  className="login_gsl_img custom-border"
                  width="150"
                  src={require("../../../src/assets/login-gsl-new.svg").default}
                />
              </a>
            </li> */}<span className="custom-border"></span>

            <li class=" cursor-pointer" onClick={(e) => goTororo()} >

              <img
                className="login_gsl_img"
                width="150"
                src={require("../../../src/assets/roro-logo.png")}
              />

            </li>
          </ul>
        </div>
        </div>
    </>
  );
};

export default Header;
